// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-commitments-js": () => import("/Users/ericbiewener/Repos/pnwhi/src/pages/commitments.js" /* webpackChunkName: "component---src-pages-commitments-js" */),
  "component---src-pages-donate-js": () => import("/Users/ericbiewener/Repos/pnwhi/src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-downloads-js": () => import("/Users/ericbiewener/Repos/pnwhi/src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-get-involved-js": () => import("/Users/ericbiewener/Repos/pnwhi/src/pages/get-involved.js" /* webpackChunkName: "component---src-pages-get-involved-js" */),
  "component---src-pages-home-js": () => import("/Users/ericbiewener/Repos/pnwhi/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/Users/ericbiewener/Repos/pnwhi/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-mission-js": () => import("/Users/ericbiewener/Repos/pnwhi/src/pages/our-mission.js" /* webpackChunkName: "component---src-pages-our-mission-js" */),
  "component---src-pages-request-js": () => import("/Users/ericbiewener/Repos/pnwhi/src/pages/request.js" /* webpackChunkName: "component---src-pages-request-js" */)
}

exports.data = () => import("/Users/ericbiewener/Repos/pnwhi/.cache/data.json")

